@tailwind components;
@tailwind utilities;

body {
  overflow: auto !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'IranSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "ss01";
  -webkit-font-feature-settings: "ss01";
  font-feature-settings: "ss01";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none !important;
}

@font-face {
  font-family: "Hevetica";
  src: url("./assets/fonts/Helvetica.otf") format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hevetica";
  src: url("./assets/fonts/Helvetica.otf") format("opentype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: IranSans;
  src: url('./assets/fonts/woff/IRANSansX-Regular.woff') format('woff'),   
	    url('./assets/fonts/woff2/IRANSansX-Regular.woff2') format('woff2');	
  font-display: swap;
}